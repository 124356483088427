//TEST COMMENT FOR PR

'use client'

import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames/bind'
import { Controller, RegisterOptions } from 'react-hook-form'

import { FinancialText, FinancialTextProps, NumberText, NumberTextProps } from '@fsg/gui-bits'

import { VALIDATIONS } from '@app/constants'
import { renderErrorMessage, usePlaceholder } from '@app/utils'

import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'
import { FormItem } from './FormItem'
import { ReadOnlyField } from './ReadOnlyField'

const cx = classNames.bind(moduleStyles)

const inputClasses = {
  base: 'text-sm font-regular block',
  editMode: 'bg-white w-full',
}

type displayOptions = NumberTextProps | FinancialTextProps

interface TextFieldProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  displayType?: 'text' | 'number' | 'percent' | 'financial'
  displayOptions?: displayOptions
  formItemProps?: React.ComponentProps<typeof FormItem>
  subtext?: React.ReactNode
  readOnly?: boolean
  characterLim?: number
}

export function TextAreaField({ rules, displayType, label, formItemProps, subtext, readOnly = false, characterLim, ...props }: TextFieldProps) {
  const {
    isEditing,
    control,
    formState: { errors },
  } = useFormContext()

  const inputClassName = cx('m--form-item--input', inputClasses.base, { [inputClasses.editMode]: isEditing })

  if (!control) {
    throw new Error('TextField must be used within a Form component')
  }

  const Display = ({ value }: { value: string | number }) => {
    const displayClassName = cx('m--form-item--input', inputClasses.base)
    const defaultPlaceholder = usePlaceholder(props.placeholder)
    if (value === undefined || value === null || value === '') {
      // console.debug('TextField >> ', {field: props.name, value})
      return <span className={cx(displayClassName, 'text-gray-dark')}>{defaultPlaceholder}</span>
    }
    try {
      value = String(value) //REVIEW -> stringify value to avoid erroring with gui-bits, but preserving any legacy implementation
      if (displayType === 'number') {
        return (
          <NumberText {...props.displayOptions} className={displayClassName}>
            {value}
          </NumberText>
        )
      }
      if (displayType === 'percent') {
        return (
          <NumberText {...props.displayOptions} percent={true} className={displayClassName}>
            {value}
          </NumberText>
        )
      }
      if (displayType === 'financial') {
        return (
          <FinancialText {...props.displayOptions} className={displayClassName}>
            {value}
          </FinancialText>
        )
      }

      return <span className={displayClassName}>{value}</span>
    } catch (e) {
      console.error(e)
      return <span className="text-error">Error</span>
    }
  }

  const formItemPropsWithOverrides = {
    label: rules?.required && isEditing ? VALIDATIONS.styleLabel(label) : label,
    ...formItemProps,
  }

  return (
    <Controller
      name={props.name}
      control={control}
      rules={{ ...rules, maxLength: characterLim }}
      render={({ field }) => {
        const charCount = field.value ? field.value.length : 0 //thuuuu
        return (
          <div className="w-full flex-col">
            {readOnly ? (
              <ReadOnlyField label={formItemPropsWithOverrides.label}>
                <Display value={field.value} />
              </ReadOnlyField>
            ) : (
              <FormItem {...formItemPropsWithOverrides}>
                {isEditing ? (
                  <div className={cx('m--textarea-wrapper')}>
                    <textarea
                      rows={5}
                      className={inputClassName}
                      {...field}
                      {...props}
                      value={field.value || ''}
                      readOnly={!isEditing}
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                    />
                    {characterLim && <div className={cx('m--textarea-character-counter')}>{`${charCount}/${characterLim}`}</div>}
                  </div>
                ) : (
                  <Display value={field.value} />
                )}
              </FormItem>
            )}
            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
            {subtext}
          </div>
        )
      }}
    />
  )
}
