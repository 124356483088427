import { PropsWithChildren } from 'react'

import classNames from 'classnames/bind'
import moduleStyles from './Form.module.scss'
const cx = classNames.bind(moduleStyles)

interface FormSectionProps extends PropsWithChildren {
  title?: string
}

export function FormSection({ title, children }: FormSectionProps) {
  return (
    <div className={cx('m--form-subgrid-item', 'bg-white rounded-md p-sm')}>
      {title ? <h4 className="text-md text-gray-darker font-semibold mb-md">{title}</h4> : null}
      <div className={cx('m--flex-col')}>{children}</div>
    </div>
  )
}
