import { Props as SelectFieldProps } from 'react-select'
import { formatIndustryOption, formatOptionLabel, industryFilterOptions } from 'src/lib/utils'

import { VALIDATIONS } from '@app/constants'
import { Industries } from '@app/types'

import { SelectField } from './SelectField'

interface Props extends SelectFieldProps {
  industries: Industries[]
  name?: string
  label?: string
  placeholder?: string
  onChange?: (value: any) => void
  required?: boolean
  readOnly?: boolean
}

export function IndustriesField({
  industries,
  name,
  label,
  placeholder,
  onChange,
  isDisabled,
  required,
  readOnly = false,
  ...selectProps
}: Props) {
  return (
    <SelectField
      isMulti
      name={name || 'industries'}
      label={label || 'Industries'}
      isSearchable
      readOnly={readOnly}
      options={industries?.map((industry) => formatIndustryOption(industry))}
      formatOptionLabel={(option: any, FormatOptionLabelMeta: any) => formatOptionLabel(option, FormatOptionLabelMeta)}
      filterOption={(option: any, input): any => {
        return industryFilterOptions(option, input)
      }}
      placeholder={placeholder || 'No industry provided'}
      onChange={onChange}
      rules={required ? { required: VALIDATIONS.REQUIRED } : null}
      {...selectProps}
    />
  )
}
