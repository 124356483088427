'use client'

import React, { forwardRef, Ref, useState } from 'react'
import classNames from 'classnames/bind'
import { format } from 'date-fns'

import { Popover, PopoverContent, PopoverTrigger } from '@fsg/gui-bits'

import moduleStyles from './DatePicker.module.scss'

import 'react-day-picker/dist/style.css'

import { CalendarClock, Caret } from '@fsg/design-system/svg/icons'
import { cn } from '@fsg/utils'

import { usePlaceholder } from '@app/utils/usePlaceholder'

import { Calendar } from './calendar'

const cx = classNames.bind(moduleStyles)

type DatePickerProps = {
  selected?: Date
  onSelect: React.Dispatch<React.SetStateAction<Date>>
  dateFormat?: string
  showCalendarIcon?: boolean
  showCaret?: boolean
  buttonClassName?: string
  placeholder?: string
  isEditing?: boolean
  label?: string
  renderSelected?: (value: { selected: Date; formatted: string }) => React.ReactNode
}

export const DatePicker = forwardRef((props: DatePickerProps, ref: Ref<HTMLButtonElement>) => {
  const { selected, onSelect, dateFormat = 'PPP', showCalendarIcon = false, showCaret = false, buttonClassName, placeholder, label } = props
  const [isOpen, setIsOpen] = useState(false)
  const defaultPlaceholder = usePlaceholder(placeholder)

  function handleOpen() {
    setIsOpen(true)
  }

  function handleClose() {
    setIsOpen(false)
  }

  function handleSelect(input: Date) {
    onSelect(input)
    handleClose()
  }

  const selectedDate = {
    selected,
    formatted: selected !== undefined && selected != null ? format(selected, dateFormat) : (defaultPlaceholder as string),
  }

  const renderedDate =
    props.renderSelected !== undefined ? (
      props.renderSelected(selectedDate)
    ) : (
      <span className="text-xs">{selected !== undefined && selected != null ? selectedDate.formatted : defaultPlaceholder}</span>
    )

  return (
    <Popover open={isOpen} onOpenChange={handleOpen}>
      <PopoverTrigger asChild>
        <div className={cn('flex cursor-pointer items-center gap-3xs whitespace-nowrap rounded-xs border border-gray p-xs', buttonClassName)}>
          {/* ICON */}
          {showCalendarIcon ? <CalendarClock /> : null}
          {/* LABEL */}
          {label ? <span className="text-sm">{label}</span> : null}
          {/* DATE */}
          {renderedDate}
          {/* CARET */}
          {showCaret && <Caret className="text-gray-darker" />}
        </div>
      </PopoverTrigger>
      <PopoverContent className="pointer-events-auto w-auto bg-white p-0">
        <Calendar
          modifiersClassNames={{
            selected: cx('selected-bg'),
          }}
          mode="single"
          selected={selected}
          onSelect={handleSelect}
        />
      </PopoverContent>
    </Popover>
  )
})
DatePicker.displayName = 'DatePicker'
