'use client'

import React from 'react'
import { Controller } from 'react-hook-form'

import { ToggleInput, ToggleInputProps } from '@fsg/gui-bits'

import { useFormContext } from './Form'
import { ReadOnlyField } from './ReadOnlyField'

interface Props extends Omit<ToggleInputProps, 'label'> {
  label: { true: string; false: string }
  toggleValue: any
  readOnly?: boolean
}

export const ToggleInputPlus = React.forwardRef((props: Props, ref: React.Ref<HTMLInputElement>) => {
  const { isEditing, control } = useFormContext()
  const { label, readOnly = false } = props
  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, isTouched, isDirty, error }, formState }) =>
        isEditing ? (
          <ToggleInput
            className={`text-sm ${readOnly ? 'text-gray-800' : ''}`}
            disabled={readOnly ? readOnly : !isEditing}
            label={props.toggleValue ? label.true : label.false}
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            checked={value}
            inputRef={ref}
          />
        ) : readOnly ? (
          <ReadOnlyField>
            <span className="text-sm">{props.toggleValue ? label.true : label.false}</span>
          </ReadOnlyField>
        ) : (
          <span className="text-sm">{props.toggleValue ? label.true : label.false}</span>
        )
      }
    />
  )
})

ToggleInputPlus.displayName = 'ToggleInputPlus'
