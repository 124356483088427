'use client'

import React from 'react'
// import pascalCase from 'just-pascal-case'
import { useFieldArray } from 'react-hook-form'

import { AddField } from './AddField'
import { ContactListField } from './ContactListField'
import { useFormContext } from './Form'

type ContactsListProps = {
  name: string
  label?: string
  placeholder: string
  contactType: 'employee' | 'customer' | 'all'
}

export function ContactsList({ name, label, placeholder, contactType }: ContactsListProps) {
  const { control } = useFormContext()
  const { fields: contactsList, append: appendContact, remove: removeContact } = useFieldArray({ control, name })

  //   const formatLabel = pascalCase(label)

  return (
    <>
      {contactsList.length ? (
        contactsList.map((field, i) => {
          return (
            <ContactListField
              removeContact={removeContact}
              contactListIndex={i}
              key={field.id}
              name={`${name}.${i}`}
              placeholder={placeholder}
              contactType={contactType}
            />
          )
        })
      ) : (
        <span className="text-sm text-gray-dark">{`No ${label} provided`}</span>
      )}
      <AddField
        label={`Add ${label}`}
        onClick={() => {
          appendContact({ label: '', value: '', id: '', data: null })
        }}
      />
    </>
  )
}
