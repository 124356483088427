'use client'

import React, { createContext, ReactNode, useContext } from 'react'

interface PageProviderProps<ContextType> extends React.PropsWithChildren {
  value: ContextType
}

const PageContext = createContext<any | undefined>(undefined)

function PageContextProvider<ContextType>({ children, value }: PageProviderProps<ContextType>) {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

function usePageContext<ContextType>() {
  const context = useContext(PageContext)
  if (context === undefined) {
    throw new Error('usePageContext must be used within a PageContextProvider')
  }
  return context as ContextType
}

export { PageContext, PageContextProvider, usePageContext }
