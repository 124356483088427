'use client'

import { Controller, useForm } from 'react-hook-form'

import { Star } from '@fsg/icons'

import { useFormContext } from './Form'

export function StarRating({ ...props }) {
  const { isEditing, control } = useFormContext()

  return (
    <></>
    // <Controller
    //   name={props.name}
    //   control={control}
    //   render={({ field }) => (
    //     <StyledRating
    //       name={props.name}
    //       value={field.value}
    //       defaultValue={0}
    //       icon={<Star />}
    //       readOnly={!isEditing}
    //       size="large"
    //       onChange={(event, newValue) => {
    //         field.onChange(newValue)
    //       }}
    //       precision={1}
    //     />
    //   )}
    // />
  )
}
