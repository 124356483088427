'use client'

import React, { useState } from 'react'
import { format } from 'date-fns'
import { Control, Controller, FieldValues, RegisterOptions } from 'react-hook-form'

import { Calendar } from '@fsg/icons'

import { VALIDATIONS } from '@app/constants'
import { usePlaceholder } from '@app/utils/usePlaceholder'

import { DatePicker } from '../ui/DatePicker'
import { useFormContext } from './Form'
import { FormItem } from './FormItem'
import { ReadOnlyField } from './ReadOnlyField'

type DatePickerProps<FormDataType> = {
  name: string
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  initialValue?: Date | string
  dateFormat?: string
  showIcon?: boolean
  showCaret?: boolean
  isEditing?: boolean
  control?: Control<FormDataType, any>
  placeholder?: string
  readOnly?: boolean
  label?: React.ReactNode
  requiredLabel?: boolean
  formItemProps?: React.ComponentProps<typeof FormItem>
}

//TODO: remove all the button props holdover, and introduce new 'disabled' handling
export function DateField<FormDataType = FieldValues>({
  rules,
  initialValue = new Date(),
  dateFormat = 'PPP',
  showIcon = false,
  showCaret = false,
  isEditing: _isEditing,
  control: _control,
  placeholder,
  readOnly = false,
  label,
  requiredLabel = false,
  formItemProps,
  ...props
}: DatePickerProps<FormDataType>) {
  const formContext = useFormContext()
  const defaultPlaceholder = usePlaceholder(placeholder)

  const isEditing = _isEditing !== undefined ? _isEditing : formContext?.isEditing !== undefined ? formContext.isEditing : true
  const control = _control || formContext.control

  const [value, setValue] = useState(() => {
    if (typeof initialValue === 'string') {
      const date = new Date(initialValue)
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()) // Adjust for time zone offset
      return date
    }
    return null
  })

  if (!control) {
    throw new Error('DateField must be used within a Form component')
  }

  const display =
    value !== undefined && value != null ? { value: format(value, dateFormat), type: 'date' } : { value: defaultPlaceholder, type: 'placeholder' }

  const DateDisplay = () => {
    return (
      <span className={`${display.type === 'placeholder' ? 'text-gray-dark' : ''} flex items-center  gap-3xs text-sm`}>
        {!readOnly ? <Calendar className="text-gray-darker" /> : null}
        {display.value}
      </span>
    )
  }

  const formItemPropsWithOverrides = {
    label: typeof label === 'string' && (rules?.required || requiredLabel) && isEditing ? VALIDATIONS.styleLabel(label) : label,
    border: false,
    ...formItemProps,
  }

  return (
    <Controller
      name={props.name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return readOnly ? (
          <ReadOnlyField label={label}>
            <DateDisplay />
          </ReadOnlyField>
        ) : (
          <FormItem {...formItemPropsWithOverrides}>
            {isEditing ? (
              <DatePicker
                buttonClassName="w-full"
                ref={field.ref}
                selected={value}
                dateFormat={dateFormat}
                showCalendarIcon={showIcon}
                showCaret={showCaret}
                isEditing={isEditing}
                placeholder={placeholder}
                onSelect={(value) => {
                  setValue(value)
                  field.onChange(value)
                }}
              />
            ) : (
              <DateDisplay />
            )}
          </FormItem>
        )
      }}
    />
  )
}
