'use client'

import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames/bind'
import debounce from 'just-debounce-it'
import { Controller, RegisterOptions } from 'react-hook-form'
import AsyncSelect from 'react-select/async'
import { CONTACT_TYPES } from 'src/lib/constants/contactTypes'

import { ButtonV2 } from '@fsg/gui-bits'
import { XCircle } from '@fsg/icons'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { ContactsQuery } from '@app/api/EntityQuery'
import { FilterQueryParams } from '@app/api/FilterQueryParams'
import { ENDPOINTS } from '@app/constants'
import { ContactResponse } from '@app/types'
import { formatContactOption, getSelectStyles, renderErrorMessage } from '@app/utils'

import { CustomerContactLookup } from '../Lookups/CustomerContactLookup'
import { EmployeeContactLookup } from '../Lookups/EmployeeContactLookup'
import { formatContactOptionLabel } from '../utilities/select/contact/formatContactOptionLabel'
import { renderContactDisplay } from '../utilities/select/contact/renderContactDisplay'
import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'

const cx = classNames.bind(moduleStyles)

const inputClasses = {
  base: 'text-sm font-regular text-true-black',

  editMode: 'bg-white',
}

interface ContactListFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  removeContact?: (index: number) => void
  contactListIndex?: number
  contactType?: 'all' | 'employee' | 'customer'
}

export function ContactListField({ rules, placeholder = 'No url provided', ...props }: ContactListFieldProps) {
  const {
    isEditing,
    control,
    formState: { errors },
    watch,
  } = useFormContext()

  const { removeContact, contactListIndex, contactType } = props

  const { request } = useRequestCallback()

  const contactData = watch(`${props.name}.data`)

  if (!control) {
    throw new Error('EmailField must be used within a Form component')
  }

  const displayClassName = cx('m--form-item--input', inputClasses.base)

  function Display({ value }: { value: ContactResponse }) {
    if (value === undefined || value === null) {
      return <span className={cx(displayClassName, 'text-gray-dark')}>{placeholder}</span>
    }
    const wrapperClassname = `${!props.readOnly ? 'py-md' : ''} text-sm font-regular`

    return <div className={wrapperClassname}>{renderContactDisplay(formatContactOption(value))}</div>
  }

  const fetchOptions = async (inputValue: string) => {
    try {
      const query = new ContactsQuery()
      const fq = new FilterQueryParams()
      const isEmployee = fq.textFilter({ field: 'contactType.code', operation: 'equals', value: CONTACT_TYPES.EMPLOYEE })
      const isCustomer = fq.textFilter({ field: 'contactType.code', operation: 'equals', value: CONTACT_TYPES.CUSTOMER })
      const hasFSGEmployeeId = fq.textFilter({ field: 'fsgEmployeeId', operation: 'notBlank' })
      const normalizedInput = inputValue.toLowerCase()

      query.search(normalizedInput)
      if (contactType === 'employee') {
        query.filter(fq.and(isEmployee, hasFSGEmployeeId))
      }

      if (contactType === 'customer') {
        query.filter(fq.and(isCustomer))
      }

      console.log(contactType, query)

      const response = await request<{ data: ContactResponse[] }>({
        path: query.getPath(),
        method: 'GET',
      })
      const options = response.data.map(formatContactOption)

      return options
    } catch (error) {
      console.error('Error fetching options:', error)
      return []
    }
  }

  const debouncedFetch = debounce((inputValue: string, resolve: (options: any[]) => void) => {
    fetchOptions(inputValue)
      .then(resolve)
      .catch((error: Error) => {
        console.error('Error in debounced fetch:', error)
        resolve([])
      })
  }, 500)

  const loadOptions = (inputValue: string): Promise<any[]> => {
    return new Promise((resolve) => {
      debouncedFetch(inputValue, resolve)
    })
  }

  return (
    <Controller
      control={control}
      name={`${props.name}`}
      rules={rules}
      render={({ field }) => {
        return (
          <div className={isEditing ? 'grid grid-cols-[1fr_1fr_1fr_auto] items-center gap-sm' : null}>
            <div className={isEditing ? 'col-span-3' : null}>
              {isEditing ? (
                <div className="w-full">
                  {contactType === 'employee' ? (
                    <EmployeeContactLookup label="" {...field} isClearable placeholder="Start typing to search..." />
                  ) : (
                    <CustomerContactLookup label="" {...field} isClearable placeholder="Start typing to search..." />
                  )}
                </div>
              ) : (
                <Display value={contactData} />
              )}
            </div>

            <div className={isEditing ? 'col-span-1 pb-1' : null}>
              {isEditing && (
                <ButtonV2
                  onClick={() => {
                    removeContact(contactListIndex)
                  }}
                  iconOnly
                  variant="error"
                  size="lg"
                >
                  <XCircle />
                </ButtonV2>
              )}
            </div>

            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
          </div>
        )
      }}
    />
  )
}
