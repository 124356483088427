'use client'

import React, { forwardRef } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames/bind'
import { Controller, RegisterOptions } from 'react-hook-form'

import { DollarSign } from '@fsg/design-system/svg/icons'
import { CurrencyInput, FinancialText, FinancialTextProps, NumberText, NumberTextProps } from '@fsg/gui-bits'
import { cn } from '@fsg/utils'

import { VALIDATIONS } from '@app/constants'
import { renderErrorMessage, usePlaceholder } from '@app/utils'

import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'
import { FormItem } from './FormItem'
import { ReadOnlyField } from './ReadOnlyField'

const cx = classNames.bind(moduleStyles)

const inputClasses = {
  base: 'text-sm w-full font-regular outline-none',
  editMode: 'bg-white',
}

type DisplayOptions = Omit<NumberTextProps | FinancialTextProps, 'children'>

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode
  requiredLabel?: boolean
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  displayType?: 'text' | 'number' | 'percent' | 'financial'
  displayOptions?: DisplayOptions
  formItemProps?: React.ComponentProps<typeof FormItem>
  subtext?: React.ReactNode
}

export function TextField({
  rules,
  displayType,
  label,
  formItemProps,
  subtext,
  displayOptions,
  className,
  readOnly = false,
  requiredLabel = false,
  type = 'text',
  ...props
}: TextFieldProps) {
  const {
    isEditing,
    control,
    formState: { errors },
  } = useFormContext()
  const inputClassName = cn(inputClasses.base, { [inputClasses.editMode]: isEditing })

  if (!control) {
    throw new Error('TextField must be used within a Form component')
  }

  if (displayType === 'financial' && type !== 'text') {
    throw new Error('Financial display type requires an input of type `text`')
  }

  const Display = ({ value }: { value: string | number }) => {
    const displayClassName = cn(inputClasses.base)
    const defaultPlaceholder = usePlaceholder(props.placeholder)
    if (value === undefined || value === null || value === '') {
      // console.debug('TextField >> ', {field: props.name, value})
      return <span className={cn(displayClassName, 'text-gray-dark')}>{defaultPlaceholder}</span>
    }

    try {
      value = String(value) //REVIEW -> stringify value to avoid erroring with gui-bits, but preserving any legacy implementation
      if (displayType === 'number') {
        return (
          <NumberText {...displayOptions} className={displayClassName}>
            {value}
          </NumberText>
        )
      }
      if (displayType === 'percent') {
        return (
          <NumberText {...displayOptions} percent={true} className={displayClassName}>
            {value}
          </NumberText>
        )
      }
      if (displayType === 'financial') {
        return (
          <FinancialText {...displayOptions} className={displayClassName}>
            {value}
          </FinancialText>
        )
      }

      return <span className={displayClassName}>{value}</span>
    } catch (e) {
      console.error(e)
      return <span className="text-error">Error</span>
    }
  }

  const maxValRules = props.max ? VALIDATIONS.NUMBER.MAX_VALUE(props.max) : {}

  const formItemPropsWithOverrides = {
    label: typeof label === 'string' && (rules?.required || requiredLabel) && isEditing ? VALIDATIONS.styleLabel(label) : label,
    className,
    ...formItemProps,
  }
  return (
    <Controller
      name={props.name}
      control={control}
      rules={displayType === 'financial' ? { ...maxValRules, ...rules } : { ...rules }}
      render={({ field }) => {
        return (
          <div className="w-full flex-col">
            {readOnly ? (
              <ReadOnlyField label={label} htmlFor={props.name}>
                <Display value={field.value} />
              </ReadOnlyField>
            ) : (
              <FormItem {...formItemPropsWithOverrides} htmlFor={props.name}>
                {isEditing ? (
                  displayType !== 'financial' ? (
                    <input
                      className={inputClassName}
                      {...field}
                      {...props}
                      type={type}
                      onChange={(event) => {
                        field.onChange(props.onChange ? props.onChange(event) : event)
                      }}
                      value={field.value ?? ''}
                      readOnly={!isEditing}
                    />
                  ) : (
                    <div className="flex items-center gap-[10px]">
                      <DollarSign className="color-gray-700" />
                      <CurrencyInput className={inputClassName} {...field} {...props} decimals={displayOptions?.decimals} />
                    </div>
                  )
                ) : (
                  <Display value={field.value} />
                )}
              </FormItem>
            )}
            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
            {subtext}
          </div>
        )
      }}
    />
  )
}
