'use client'

import * as React from 'react'
// import { ChevronLeft, ChevronRight } from 'lucide-react'
import { DayPicker } from 'react-day-picker'

import { ArrowLeft, ArrowRight } from '@fsg/icons'

import { cn } from '@app/utils'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(className)}
      classNames={{
        cell: 'text-[10px]',
        ...classNames,
      }}
      components={{
        // IconLeft: ({ ...props }) => <ArrowLeft className="h-4 w-4" />,
        // IconRight: ({ ...props }) => <ArrowRight className="h-4 w-4" />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
