'use client'

import { useFieldArray } from 'react-hook-form'

import { AddField } from './AddField'
import { useFormContext } from './Form'
import { FormItem } from './FormItem'
import { SelectField } from './SelectField'
import { TextField } from './TextField'

type ConnectedAccountsProps = {
  options: any
  name: string
}

export function ConnectedAccounts({ options, name }: ConnectedAccountsProps) {
  const { control } = useFormContext()
  const { fields: connectedAccounts, append: appendAccount } = useFieldArray({ control, name })

  return (
    <>
      {connectedAccounts.length ? (
        connectedAccounts.map((field, i) => {
          return (
            <div key={field.id} className="grid-2 grid">
              <FormItem label="Account Name" className="m--span-half">
                <TextField name={`${name}.${i}.name`} />
              </FormItem>
              {/* ! review how this should work */}
              <FormItem label="Role" className="m--span-half" border={false}>
                <SelectField isMulti name={`${name}.${i}.roles`} options={options} />
              </FormItem>
            </div>
          )
        })
      ) : (
        <span className="text-sm text-gray-dark">No links provided</span>
      )}
      <AddField
        label="Connect Additional Account"
        onClick={() => {
          appendAccount({
            name: '',
            id: '',
            createdAt: new Date(),
            updatedAt: new Date(),
          })
        }}
      />
    </>
  )
}
