'use client'

import React from 'react'
import { useFieldArray } from 'react-hook-form'

import { AddField } from './AddField'
import { useFormContext } from './Form'
import { WebLinkField } from './WebLinkField'

type WebLinksProps = {
  name: string
}

export function WebLinks({ name }: WebLinksProps) {
  const { control } = useFormContext()
  const { fields: weblinks, append: appendWebLink, remove: removeWebLink } = useFieldArray({ control, name })

  return (
    <>
      {weblinks.length ? (
        weblinks.map((field, i) => {
          return <WebLinkField removeWebLink={removeWebLink} webLinkIndex={i} key={field.id} name={`${name}.${i}`} placeholder="No link provided" />
        })
      ) : (
        <span className="text-sm text-gray-dark">No links provided</span>
      )}
      <AddField
        label="Add Web Link"
        onClick={() => {
          appendWebLink({ label: '', url: '' })
        }}
      />
    </>
  )
}
