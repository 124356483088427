'use client'

import React, { useEffect, useMemo, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { useFieldArray } from 'react-hook-form'
import { formatCallingCodeOption, handleRemove, renderErrorMessage, setPrimaryField } from 'src/lib/utils'

import {
  AccountResponse,
  ActivityResponse,
  ContactResponse,
  CountryType,
  LeadResponse,
  OpportunityResponse,
  PhoneFormData,
  SiteResponse,
} from '@app/types'

import { AddField } from './AddField'
import { useFormContext } from './Form'
import { PhoneNumberField } from './PhoneNumberField'

type PhonesProps = {
  name: string
  countries: CountryType[]
  entity: AccountResponse | LeadResponse | OpportunityResponse | ContactResponse | SiteResponse
  updateState: React.Dispatch<React.SetStateAction<ActivityResponse[]>>
  opportunity?: OpportunityResponse
  disablePrimary?: boolean
  numberRequired?: number
}

export function Phones({ name, countries, entity, updateState, opportunity, disablePrimary, numberRequired }: PhonesProps) {
  const {
    control,
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    isEditing,
  } = useFormContext()

  const { fields: phoneNumbers, append: appendPhone, remove: removePhone } = useFieldArray({ control, name: 'phones' })

  //useEffect watches the length of the array to throw an error if there's a required amount of fields
  useEffect(() => {
    if (isEditing && numberRequired && phoneNumbers.length < numberRequired) {
      setError(`${name}.arrayError`, {
        type: 'manual',
        message:
          numberRequired <= 1
            ? `You must proved at least ${numberRequired} phone number`
            : `You must proved at least ${numberRequired} phone numbers`,
      })
    } else {
      clearErrors(name)
    }
  }, [numberRequired, phoneNumbers.length, isEditing, clearErrors, name, setError])

  //used to reset the fields when the user attempts to remove the required phone number
  function resetFieldArrayEntry(index: number) {
    setValue(`${name}.${index}.label`, '')
    setValue(`${name}.${index}.number`, '')
    setValue(`${name}.${index}.id`, '')
  }

  //logic interception to check for field requirement before allowing user to remove required number
  function handleRemovePhone(index: number) {
    const primaryIndex = getValues('setPrimaryPhone')
    handleRemove({
      entity: 'phone number',
      index: index as number,
      primaryIndex: primaryIndex,
      numberRequired: numberRequired,
      primaryField: 'setPrimaryPhone',
      name: `${name}.arrayError`,
      array: phoneNumbers,
      setError: setError,
      clearError: clearErrors,
      removeItem: removePhone,
      setField: setPrimaryField,
      resetFields: resetFieldArrayEntry,
      setValue: setValue,
    })
  }

  const callingCodeOptionsMap = useMemo(() => {
    return new Map(countries.map((country) => [country.alpha2code, formatCallingCodeOption(country)]))
  }, [countries])

  return (
    <>
      {phoneNumbers.length ? (
        phoneNumbers.map((field, i) => (
          <PhoneNumberField
            numberRequired={numberRequired}
            opportunity={opportunity ?? null}
            updateState={updateState}
            entity={entity}
            removePhone={handleRemovePhone}
            phoneIndex={i}
            countries={countries}
            key={field.id}
            group={name}
            name={`${name}.${i}`}
            disablePrimary={disablePrimary}
          />
        ))
      ) : (
        <span className="text-sm text-gray-dark">No phone numbers provided</span>
      )}
      <div className="w-fit">
        <ErrorMessage errors={errors} name={`${name}.arrayError`} render={renderErrorMessage} />
      </div>
      <AddField
        label="Add Phone Number"
        onClick={() => {
          appendPhone({
            label: 'Phone',
            number: '',
            id: '',
            callingCode: callingCodeOptionsMap.get('US'),
            extension: '',
          })
        }}
      />
    </>
  )
}
