'use client'

import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames/bind'
import { Controller, RegisterOptions } from 'react-hook-form'
import AsyncSelect from 'react-select/async'

import { ButtonV2 } from '@fsg/gui-bits'
import { ExternalLink, XCircle } from '@fsg/icons'
import { useRequestCallback } from '@fsg/next-auth/useRequest'
import { normalizeUrl } from '@fsg/utils'

import { ENDPOINTS } from '@app/constants'
import { AccountResponse } from '@app/types'
import { getSelectStyles, renderErrorMessage, routes } from '@app/utils'

import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'
import { FormItem } from './FormItem'

const cx = classNames.bind(moduleStyles)

const inputClasses = {
  base: 'text-sm font-regular text-true-black',

  editMode: 'bg-white',
}

interface AccountListFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string

  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>

  removeAccount?: (index: number) => void

  accountListIndex?: number
}

export function AccountListField({ rules, placeholder = 'No url provided', ...props }: AccountListFieldProps) {
  const {
    isEditing,

    control,

    formState: { errors },

    register,
    watch,

    setValue,
  } = useFormContext()

  const { removeAccount, accountListIndex } = props

  const { request } = useRequestCallback()

  const accountName = watch(`${props.name}.label`)
  const accountData = watch(`${props.name}.data`)
  const accountId = watch(`${props.name}.value`)

  const url = routes.account.basicInformation(accountId)

  if (!control) {
    throw new Error('EmailField must be used within a Form component')
  }

  const displayClassName = cx('m--form-item--input', inputClasses.base)

  const inputClassName = cx('m--form-item--input', inputClasses.base, { [inputClasses.editMode]: isEditing })

  function Display({ value }: { value: AccountResponse }) {
    // console.log(value) // REVIEW >> Please don't log without any context (on server side there's no breadcrumbs to indicate where the log is coming from and so it's harder to suppress) #code-review--standards
    if (value.name === undefined || value.name === null || value.name === '') {
      return <span className={cx(displayClassName, 'text-gray-dark')}>{placeholder}</span>
    }

    return <span className={displayClassName}>{value.name}</span>
  }

  const fetchAccounts = async (inputValue: string) => {
    const normalizedInput = inputValue.toLowerCase()

    try {
      const response = await request<{ data: AccountResponse[] }>({
        path: `${ENDPOINTS.ACCOUNTS}?search=${normalizedInput}`,

        method: 'GET',
      })

      const options = response.data.map((account: any) => ({
        label: `${account.name}`,

        value: account.id,
        data: account,
      }))

      // console.log(options)

      return options
    } catch (error) {
      console.error('Error fetching options:', error)

      return []
    }
  }

  return (
    <Controller
      control={control}
      name={`${props.name}`}
      rules={rules}
      render={({ field }) => {
        return (
          <div className={isEditing ? 'grid grid-cols-[1fr_1fr_1fr_auto] items-center gap-sm' : null}>
            <div className={isEditing ? 'col-span-3' : null}>
              <FormItem border={false} label={props.label || ''} icon={ExternalLink} iconHref={isEditing ? '' : url}>
                {isEditing ? (
                  <div className="w-full">
                    <AsyncSelect {...getSelectStyles()} {...field} isClearable placeholder="Start typing to search..." loadOptions={fetchAccounts} />
                  </div>
                ) : (
                  <Display value={accountData} />
                )}
              </FormItem>
            </div>

            <div className={isEditing ? 'col-span-1 pb-1' : null}>
              {isEditing && (
                <ButtonV2
                  onClick={() => {
                    removeAccount(accountListIndex)
                  }}
                  iconOnly
                  variant="error"
                  size="lg"
                >
                  <XCircle />
                </ButtonV2>
              )}
            </div>

            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
          </div>
        )
      }}
    />
  )
}
