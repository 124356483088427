'use client'

import React, { ReactNode } from 'react'

import { LockKeyhole } from '@fsg/icons'
import { cn } from '@fsg/utils'

import { useFormContext } from './Form'
import { Label } from './Label'

type Props = {
  children: ReactNode
  label?: any
  htmlFor?: string
  className?: string
}

export function ReadOnlyField({ children, label = '', htmlFor, className }: Props) {
  const { isEditing } = useFormContext()

  const valueWrapperClass = cn(
    'grid h-full w-full grid-cols-[1.25em_auto] items-center py-xs disabled:bg-gray-200 disabled:border-gray-400',
    {
      'border border-solid border-gray-500 pl-sm pr-xs rounded-xs hover:border-color-primary has-[:focus]:border-color-primary': isEditing,
      'border-b border-solid border-gray-300': !isEditing,
    },
    className,
  )

  return (
    <div>
      <Label label={label} htmlFor={htmlFor} />
      <div className={valueWrapperClass}>
        <LockKeyhole />
        <span>{children}</span>
      </div>
    </div>
  )
}
