'use client'

import React, { createContext, FormHTMLAttributes, forwardRef, useContext } from 'react'
import {
  FieldValues,
  FormProvider as ReactHookFormProvider,
  UseFormReturn,
  useFormContext as useReactHookFormContext,
} from 'react-hook-form'

import { useBeforeUnload } from '@app/utils'

export interface FormContextType<T = any> extends UseFormReturn<T> {
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

interface FormProps<T = any> extends FormHTMLAttributes<HTMLFormElement> {
  form: UseFormReturn<T>
  isEditing?: boolean // * Defaults to true in prop spread
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
}

const EditStateContext = createContext(undefined)

export const Form = forwardRef<HTMLFormElement, FormProps>((props, ref) => {
  const { form, children, isEditing = true, setIsEditing, ...rest } = props

  useBeforeUnload(form)

  const editStateContext = {
    isEditing,
    setIsEditing,
  }

  return (
    <EditStateContext.Provider value={editStateContext}>
      <ReactHookFormProvider {...form}>
        <form {...rest} ref={ref}>
          {children}
        </form>
      </ReactHookFormProvider>
    </EditStateContext.Provider>
  )
})
Form.displayName = 'Form'

export function useFormContext<T = FieldValues>() {
  const reactHookFormContext = useReactHookFormContext<T>()
  const editStateContext = useContext(EditStateContext)

  if (reactHookFormContext === undefined) {
    throw new Error('Cannot call useFormContext outside of a Form context provider.')
  }

  const combinedContextValues = {
    ...reactHookFormContext,
    ...editStateContext,
  }

  return combinedContextValues as FormContextType<T>
}
