import { cn } from '@fsg/utils'

type Props = {
  label: React.ReactNode
  htmlFor?: string
  className?: string
}

export function Label({ label, htmlFor, className }: Props) {
  if (!label) return null

  return typeof label === 'string' ? (
    <label htmlFor={htmlFor} className={cn('text-sm text-gray-darker', className)}>
      {label}
    </label>
  ) : (
    // if label is a ReactNode
    label
  )
}
