import { FormState } from 'react-hook-form'

type Registry = Map<string, FormState<any>>

export class FormRegistry {
  constructor(public registry: Registry = new Map()) {}

  registerForm(name: string, formState: FormState<any>) {
    this.registry.set(name, formState)
  }

  unregisterForm(name: string) {
    this.registry.delete(name)
  }

  private checkIfFormIsDirty(formState: FormState<any>) {
    const { isDirty, dirtyFields } = formState
    // Note: Until we fix default values in all forms, this check is most reliable
    const formIsDirty = isDirty && dirtyFields && Object.keys(dirtyFields).length > 0
    return formIsDirty
  }

  public allowsNavigation() {
    return Array.from(this.registry).some(([_, formState]) => this.checkIfFormIsDirty(formState))
  }
}
