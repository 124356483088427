'use client'

import React, { HtmlHTMLAttributes, PropsWithChildren } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import { Mail } from '@fsg/icons'
import { useRequestCallback } from '@fsg/next-auth/useRequest'
import { cn } from '@fsg/utils'

import { ACTIVITY_ENTITIES } from '@app/constants'
import { AccountResponse, ActivityResponse, ContactResponse, LeadResponse, OpportunityResponse } from '@app/types'
import {
  addActivityNote,
  addEmailActivity,
  formatEntityEmailData,
  getEntityTypeFromPathname,
  parseAddressInMailto,
  useEmailClipboard,
} from '@app/utils'

type Props = {
  className?: string
  entity?: AccountResponse | LeadResponse | OpportunityResponse | ContactResponse
  opportunity?: OpportunityResponse
  forceMailTo?: string
  entityId: string
  updateState: React.Dispatch<React.SetStateAction<ActivityResponse[]>>
  activityEntityType?: ACTIVITY_ENTITIES
  iconClass?: string
} & HtmlHTMLAttributes<HTMLAnchorElement>

//TODO: this component is weird and needs to be refactored, maybe accept the email address, subject, and body as props instead of the weird forceMailTo prop
export function EmailButton({
  className,
  entity,
  opportunity,
  forceMailTo,
  updateState,
  activityEntityType: _activityEntityType,
  children,
  iconClass,
  ...anchorProps
}: PropsWithChildren<Props>) {
  const pathname = usePathname()
  const copyEmailToClipboard = useEmailClipboard()
  const { request } = useRequestCallback()
  const router = useRouter()
  let mailto: string
  let address: string | string[]

  const emailData = entity ? formatEntityEmailData(entity) : null

  const activityEntityType = _activityEntityType || getEntityTypeFromPathname(pathname)

  //the forceMailTo needs to be parsed and pull the email address from it. if the parsed email is not null/undefined, use it as the email address instead of addresses[0].address
  if (forceMailTo) {
    mailto = forceMailTo
    const parsedEmail = parseAddressInMailto(mailto)
    address = parsedEmail && (parsedEmail.length > 1 ? parsedEmail.toString() : parsedEmail[0])
  } else {
    address = emailData ? emailData.addresses[0].address : null
    //simple check to see if the address is an email address, if not, set it to null
    if (address && !address.includes('@')) {
      address = null
    }
    mailto = opportunity && emailData?.mailto !== '' ? `${emailData.mailto}&subject=${opportunity?.name}` : emailData.mailto
  }
  if (!address) return null

  async function logToOpportunity(noteContent: string) {
    const activity = await addEmailActivity(request, ACTIVITY_ENTITIES.OPPORTUNITY, opportunity.id)
    const note = await addActivityNote(request, activity.id, noteContent)
    return { activity, note }
  }

  async function logToEntity(noteContent: string) {
    const activity = await addEmailActivity(request, opportunity ? ACTIVITY_ENTITIES.CONTACT : activityEntityType, entity.id)
    const note = await addActivityNote(request, activity.id, noteContent)
    return { activity, note }
  }

  async function logActivity() {
    try {
      let newActivity: ActivityResponse

      if (opportunity) {
        const entityNoteContent = `Email sent to ${entity ? emailData.name : null} (${address}) Regarding ${opportunity.name} (Opportunity)`
        const oppNoteContent = `Email sent to ${entity ? emailData.name : null} (${address})`

        await logToEntity(entityNoteContent)
        const { activity, note } = await logToOpportunity(oppNoteContent)

        newActivity = { ...activity, notes: [note] }
      } else {
        const noteContent = `Email sent to ${entity ? emailData.name : null} (${address})`
        const { activity, note } = await logToEntity(noteContent)
        newActivity = { ...activity, notes: [note] }
      }

      updateState((prevState) => [newActivity, ...prevState])
      window.open(mailto, '_blank')
      router.refresh()
    } catch (err) {
      console.error(err)
    }
  }

  async function handleOnClick() {
    await copyEmailToClipboard(address ?? '')
    await logActivity()
  }

  const _iconClass = cn('inline-block bg-gray-lightest rounded-xs p-3xs h-[24px] w-[24px]', iconClass)

  return (
    <a onClick={handleOnClick} className={cn('cursor-pointer', className)} {...anchorProps}>
      <Mail className={_iconClass} />
      {children}
    </a>
  )
}
