import classNames from 'classnames/bind'

import { Plus } from '@fsg/icons'

import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'

const cx = classNames.bind(moduleStyles)

interface AddFieldProps {
  label: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export function AddField(props: AddFieldProps) {
  const { isEditing } = useFormContext()
  const { label, onClick } = props

  return isEditing ? (
    <button
      type="button"
      onClick={onClick}
      className={cx('m--flex-row', 'mt-sm rounded-xs border border-dashed border-gray px-sm py-xs text-sm text-gray-darker-600 font-regular')}
    >
      <Plus className="mr-lg rounded-full border border-solid border-gray-dark" />
      {label}
    </button>
  ) : null
}
