'use client'

import { Fragment, useEffect } from 'react'
import { Controller } from 'react-hook-form'

import { Button } from '@fsg/gui-bits'
import { Star } from '@fsg/icons'

import { Field } from '../Create'
import { useFormContext } from './Form'

type PreferredIndexButtonProps = {
  group: string
  fieldName: string
  index: number
}

// REVIEW >> Star icon has different dimensions depending on isEditing (not the end of the world, but not ideal)
export function PreferredIndexButton(props: PreferredIndexButtonProps) {
  const { isEditing, control, setValue, watch } = useFormContext()
  const { group, index, fieldName, ...rest } = props
  const watchedField = watch(props.fieldName)
  const primaryIndex = watchedField === -1 ? 0 : watchedField

  const isPrimary = primaryIndex === index //always convert to number

  useEffect(() => {
    if (watchedField === -1) {
      setValue(props.fieldName, 0)
    }
  }, [watchedField, setValue, props.fieldName])

  return (
    <Controller
      name={props.fieldName}
      control={control}
      render={({ field }) => {
        return isEditing ? (
          <Fragment key={`${group}${index}`}>
            <input
              type="radio"
              tabIndex={-1}
              className="absolute h-0 w-0 overflow-hidden opacity-0"
              {...field}
              name={group}
              value={index}
              checked={isPrimary}
            />
            <Star
              onClick={() => {
                field.onChange(index)
              }}
              className={`pointer-events-auto cursor-pointer text-[17px] ${isPrimary ? 'text-color-warning' : 'text-gray'}`}
            />
          </Fragment>
        ) : isPrimary ? (
          <Star className="text-[17px] text-color-warning" />
        ) : null
      }}
    />
  )
}
