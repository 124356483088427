'use client'

import { PropsWithChildren } from 'react'

import { cn } from '@fsg/utils'

import { useFormContext } from './Form'
import { Label } from './Label'

interface FormItemProps extends PropsWithChildren {
  label?: string | React.ReactNode
  icon?: JSX.Element | React.ComponentType<any> // JSX.Element for passing an Icon directly, React.ComponentType<any> for a function or JSX that gets rendered in the icon slot
  labelOnly?: boolean
  iconHref?: string
  className?: string
  border?: boolean
  readOnly?: boolean
  htmlFor?: string
}

export function FormItem({
  label,
  icon: Icon,
  children,
  iconHref,
  className,
  labelOnly = false,
  border = true,
  readOnly = false,
  htmlFor,
}: FormItemProps) {
  const { isEditing } = useFormContext()

  if (labelOnly)
    return (
      <div className={cn('w-full', className)}>
        <Label label={label} htmlFor={htmlFor} />
        {children}
      </div>
    )

  const inputWrapperClassName = cn('text-sm w-full py-xs disabled:bg-gray-200 disabled:border-gray-400', {
    'border-none': !border,
    'border border-solid border-gray-500 pl-sm pr-xs rounded-xs hover:border-color-primary has-[:focus]:border-color-primary': isEditing && border,
    'border-b border-solid border-gray-300': !isEditing,
  })

  return (
    <div className={cn(className)}>
      <Label label={label} htmlFor={htmlFor} />
      <div className={`${inputWrapperClassName} ${readOnly && isEditing && border ? 'bg-gray-100' : ''}`}>
        {children}
        {iconHref && !isEditing ? (
          <a href={iconHref} target="_blank" rel="noreferrer">
            {' '}
            {Icon && !isEditing ? typeof Icon === 'function' ? <Icon /> : Icon : null}
          </a>
        ) : (
          <>{Icon && !isEditing ? typeof Icon === 'function' ? <Icon /> : Icon : null}</>
        )}
      </div>
    </div>
  )
}
