'use client'

import React from 'react'
import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames/bind'
import { Controller, RegisterOptions } from 'react-hook-form'
import { formatEntityEmailData, generateGreeting, usePlaceholder } from 'src/lib/utils'

import { ButtonV2 } from '@fsg/gui-bits'
import { XCircle } from '@fsg/icons'
import { pattern, truncateString } from '@fsg/utils'

import { EmailButton } from '@app/components/EmailButton'
import { PreferredIndexButton } from '@app/components/Form/PreferredIndexButton'
import { AccountResponse, ActivityResponse, ContactResponse, LeadResponse, OpportunityResponse } from '@app/types'
import { renderErrorMessage } from '@app/utils'

import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'
import { FormItem } from './FormItem'

const cx = classNames.bind(moduleStyles)

const inputClasses = {
  base: 'text-sm font-regular text-true-black',
  editMode: 'bg-white',
}

interface EmailFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  removeEmail: (index: number) => void
  emailIndex: number
  entity: AccountResponse | LeadResponse | OpportunityResponse | ContactResponse
  updateState: React.Dispatch<React.SetStateAction<ActivityResponse[]>>
  opportunity?: OpportunityResponse
  disablePrimary?: boolean
  group?: string
}

export function EmailField({
  rules,
  entity,
  placeholder = 'No email provided',
  updateState,
  opportunity,
  disablePrimary,
  group,
  removeEmail,
  emailIndex,
  ...props
}: EmailFieldProps) {
  const {
    isEditing,
    control,
    formState: { errors },
  } = useFormContext()
  if (!control) {
    throw new Error('EmailField must be used within a Form component')
  }

  const emailData = entity ? formatEntityEmailData(entity) : null
  const opportunitySubject = emailData?.type === 'opportunity' ? `&subject=${emailData.name}` : null
  const greeting = emailData?.type === 'lead' || emailData?.type === 'contact' ? generateGreeting(emailData?.name) : null
  const displayClassName = cx('m--form-item--input', inputClasses.base)
  const inputClassName = cx('m--form-item--input', inputClasses.base, { [inputClasses.editMode]: isEditing })

  //   function Label({ isPreferred }: { isPreferred: boolean}) {
  //     return (
  //       <div className={cx('m--label-row', 'text-sm text-gray-darker')}>
  //         <TextField name={`emails.${i}.label`} />
  //         <PreferredIndexButton
  //           isPreferred={isPreferred}
  //           onClick={() => {
  //             setPreferredEmailIndex(i)
  //             setValue('tempEmailIndex', i)
  //           }}
  //         />
  //       </div>
  //     )
  //   }

  function Display({ value }: { value: string }) {
    const defaultPlaceholder = usePlaceholder(placeholder)
    if (value === undefined || value === null || value === '') {
      return <span className={cx(displayClassName, 'text-gray-dark')}>{defaultPlaceholder}</span>
    }

    return <span className={displayClassName}>{truncateString(value, 40)}</span>
  }

  return (
    <Controller
      name={`${props.name}.address`}
      control={control}
      rules={{
        // REVIEW >> We need better validation and validation messaging.  The capitalized chars bug was only being caught AppForm > submitErrorHandler with no reference to which field. #code-review
        pattern: pattern.email,
        validate: (value) => {
          if (!value || !value.trim()) {
            return 'A valid email address is required'
          }
          return true
        },
      }}
      render={({ field }) => {
        return (
          <div className={isEditing ? 'grid grid-cols-[1fr_1fr_1fr_auto] items-end gap-sm' : null}>
            <div className={isEditing ? 'col-span-3' : null}>
              <FormItem
                label={
                  disablePrimary ? (
                    'Email'
                  ) : (
                    <div className="flex items-center  gap-3xs text-sm text-gray-darker">
                      Email
                      <PreferredIndexButton group={group} index={emailIndex} fieldName="setPrimaryEmail" />
                    </div>
                  )
                }
                icon={
                  <EmailButton
                    opportunity={opportunity ?? null}
                    updateState={updateState}
                    entity={entity}
                    entityId={entity?.id}
                    forceMailTo={`mailto:${field.value}${entity ? `?body=${greeting}${opportunitySubject ?? ''}` : ''}`}
                    iconClass="bg-transparent h-auto w-auto float-right"
                  />
                }
              >
                {isEditing ? (
                  <div className="w-full">
                    <input type="email" className={inputClassName} {...field} {...props} placeholder={placeholder} readOnly={!isEditing} />
                  </div>
                ) : (
                  <Display value={field.value} />
                )}
              </FormItem>
            </div>
            <div className={isEditing ? 'col-span-1 pb-1' : null}>
              {isEditing && (
                <ButtonV2
                  type="button"
                  onClick={() => {
                    removeEmail(emailIndex)
                  }}
                  iconOnly
                  variant="error"
                  size="lg"
                >
                  <XCircle />
                </ButtonV2>
              )}
            </div>
            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
          </div>
        )
      }}
    />
  )
}
