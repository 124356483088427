'use client'

import React, { forwardRef } from 'react'
import debounce from 'just-debounce-it'
import { ControllerRenderProps, FieldValues, RegisterOptions } from 'react-hook-form'
import { GroupBase, Props as SelectProps } from 'react-select'

import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { ENDPOINTS } from '@app/constants'
import { ContactOption, ContactResponse, ENTITY, SelectOption } from '@app/types'
import { formatContactOption } from '@app/utils/selectOptions'

import { Typeahead } from '../Form'
import { formatContactOptionLabel } from '../utilities/select/contact/formatContactOptionLabel'
import { renderContactDisplay } from '../utilities/select/contact/renderContactDisplay'
import { FilterQueryParams } from '@app/api/FilterQueryParams'
import { useContactFilter } from './useContactFilter'


export interface ContactLookupProps extends SelectProps<ContactOption, boolean, GroupBase<ContactOption>> {
  name: string
  placeholder?: string
  label: React.ReactNode
  isMulti?: boolean
  employee?: boolean
  readOnly?: boolean
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
}

export const CustomerContactLookup = forwardRef<any, ContactLookupProps>((props, ref) => {
  const { request } = useRequestCallback()
  const { name, placeholder, label, isMulti, employee = false, readOnly = false, ...selectProps } = props

  const customerContactFilter = useContactFilter('C')

  const fetchOptions = async (inputValue: string) => {
    try {
      // const query = new ContactsQuery()
      const fq = new FilterQueryParams()
      // const isCustomer = fq.textFilter({ field: 'contactType.code', operation: 'equals', value: CONTACT_TYPES.CUSTOMER })
      const normalizedInput = inputValue.toLowerCase()

      // query.search(normalizedInput)
      // query.filter(fq.and(isCustomer))
      //FSG-8723 story to rework EntityQuery in a way that supports the standard entity & entity search endpoints

      const searchPath = `${ENDPOINTS.ENTITY_SEARCH(ENTITY.CONTACTS, normalizedInput)}&filter=${customerContactFilter}&limit=5000`

      const response = await request<{ data: ContactResponse[] }>({
        path: searchPath,
        method: 'GET',
      })
      const options = response.data.map(formatContactOption)

      return options
    } catch (error) {
      console.error('Error fetching options:', error)
      return []
    }
  }

  const debouncedFetch = debounce((inputValue: string, resolve: (options: any[]) => void) => {
    fetchOptions(inputValue)
      .then(resolve)
      .catch((error: Error) => {
        console.error('Error in debounced fetch:', error)
        resolve([])
      })
  }, 500)

  const loadOptions = (inputValue: string): Promise<any[]> => {
    return new Promise((resolve) => {
      debouncedFetch(inputValue, resolve)
    })
  }

  type ContactOption = SelectOption<string> & {
    data: ContactResponse
  }

  type DisplayProps = ControllerRenderProps<FieldValues>
  function Display(field: DisplayProps) {
    const wrapperClassname = `${!readOnly ? 'py-xs' : ''} text-sm font-regular`
    if (isMulti) {
      return <div className={wrapperClassname}>{field.value.map(renderContactDisplay)}</div>
    } else {
      return <div className={wrapperClassname}>{renderContactDisplay(field.value as ContactOption)}</div>
    }
  }

  const renderDisplayComponent = (displayProps: DisplayProps) => <Display {...displayProps} />

  return (
    <Typeahead
      name={name}
      noOptionsMessage={({ inputValue }) => (inputValue ? `No results for ${inputValue}` : `Start typing to select ${label}`)}
      isMulti={isMulti ?? false}
      label={label}
      placeholder={placeholder}
      loadOptions={loadOptions}
      renderDisplayComponent={renderDisplayComponent}
      {...selectProps}
      ref={ref}
      readOnly={readOnly}
      formatOptionLabel={formatContactOptionLabel}
    />
  )
})
CustomerContactLookup.displayName = 'CustomerContactLookup'
