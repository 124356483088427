'use client'
import React from 'react'
import { Controller } from 'react-hook-form'

import { ToggleInput } from '@fsg/gui-bits'

import { useFormContext } from './Form'
import { FormItem } from './FormItem'
import { ReadOnlyField } from './ReadOnlyField'

interface Props extends React.ComponentProps<typeof ToggleInput> {
  name: string
  label: string
  readOnly?: boolean
}

export default function ToggleField({ name, label, readOnly = false, ...toggleInputProps }: Props) {
  const { isEditing, register, control } = useFormContext()

  return (
    <FormItem label={label} border={false}>
      {isEditing ? (
        <ToggleInput label="" disabled={readOnly ? readOnly : !isEditing} {...toggleInputProps} {...register(name)} />
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            // console.log(field)
            return readOnly ? (
              <ReadOnlyField>
                <span {...field}>{printValue(field.value)}</span>
              </ReadOnlyField>
            ) : (
              <span {...field}>{printValue(field.value)}</span>
            )
          }}
        />
      )}
    </FormItem>
  )
}

function printValue(value: any) {
  return value ? 'Yes' : 'No'
}
