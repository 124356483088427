'use client'

import React, { HtmlHTMLAttributes, PropsWithChildren } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import { useNotification } from '@fsg/gui-bits'
import { PhoneOutgoing } from '@fsg/icons'
import { useRequestCallback } from '@fsg/next-auth/useRequest'
import { cn } from '@fsg/utils'

import { ACTIVITY_ENTITIES } from '@app/constants'
import { AccountResponse, ActivityResponse, ContactResponse, LeadResponse, OpportunityResponse, SiteResponse } from '@app/types'
import { addActivityNote, addPhoneCallActivity, formatEntityEmailData, getEntityTypeFromPathname } from '@app/utils'

type Props = {
  phoneNumber: string
  className?: string
  entityId: string
  entity?: AccountResponse | LeadResponse | OpportunityResponse | ContactResponse | SiteResponse
  opportunity?: OpportunityResponse
  updateState: React.Dispatch<React.SetStateAction<ActivityResponse[]>>
  activityEntityType?: ACTIVITY_ENTITIES
  iconClass?: string
} & HtmlHTMLAttributes<HTMLAnchorElement>

export function PhoneButton({
  phoneNumber,
  className,
  opportunity,
  entity,
  updateState,
  activityEntityType: _activityEntityType,
  children,
  iconClass,
  ...anchorProps
}: PropsWithChildren<Props>) {
  const pathname = usePathname()
  const notification = useNotification()
  const { request } = useRequestCallback()
  const router = useRouter()
  const entityData = formatEntityEmailData(entity)

  const activityEntityType = _activityEntityType || getEntityTypeFromPathname(pathname)
  function showCopiedMessage() {
    notification.api.notify({
      type: 'success',
      message: 'Copied phone to clipboard!',
      duration: 3000,
    })
  }

  async function copyPhoneToClipboard() {
    try {
      await navigator.clipboard.writeText(phoneNumber)
      showCopiedMessage()
    } catch (err) {
      console.error('Failed to copy: ', err)
      notification.api.notify({
        type: 'alert',
        message: 'Could not copy!',
        duration: 3000,
      })
    }
  }

  async function logToOpportunity() {
    //post activity to opportunity entity
    const activity = await addPhoneCallActivity(request, ACTIVITY_ENTITIES.OPPORTUNITY, opportunity.id)
    //set note content for opportunity activity log (no need for origin)
    const content = `Phone call made to ${entity ? entityData.name : null} (${phoneNumber})`
    //post content to opportunity activity
    const note = await addActivityNote(request, activity.id, content)
    return { activity, content, note }
  }

  async function logToEntity(content: string) {
    //post activity to entity
    const activity = await addPhoneCallActivity(request, opportunity ? ACTIVITY_ENTITIES.CONTACT : activityEntityType, entity.id)
    //post content to activity
    const note = await addActivityNote(request, activity.id, content)
    return { activity, note }
  }

  async function logActivity() {
    try {
      let newActivity: ActivityResponse
      if (opportunity) {
        await logToEntity(`Phone call made to ${entity ? entityData.name : null} (${phoneNumber}) Regarding ${opportunity.name} (Opportunity)`)
        const { activity, note } = await logToOpportunity()
        //spread to activity log table
        newActivity = { ...activity, notes: [note] }
      } else {
        const { activity, note } = await logToEntity(`Phone call made to ${entity ? entityData.name : null} (${phoneNumber})`)
        newActivity = { ...activity, notes: [note] }
      }

      updateState((prevState) => [...prevState, newActivity])
      window.open(`tel:${phoneNumber}`, '_blank')
      router.refresh()
    } catch (err) {
      console.error(err)
    }
  }

  async function handleOnClick() {
    await copyPhoneToClipboard()
    await logActivity()
  }

  const _iconClass = cn('inline-block bg-gray-lightest rounded-xs p-3xs h-[24px] w-[24px]', iconClass)

  return (
    <a onClick={handleOnClick} className={cn('cursor-pointer', className)} {...anchorProps}>
      <PhoneOutgoing className={_iconClass} />
      {children}
    </a>
  )
}
