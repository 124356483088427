'use client'

import React from 'react'
import { useFieldArray } from 'react-hook-form'

import { AccountResponse, ActivityResponse, ContactResponse, LeadResponse, OpportunityResponse } from '@app/types'

import { AddField } from './AddField'
import { EmailField } from './EmailField'
import { useFormContext } from './Form'
import { PreferredIndexButton } from './PreferredIndexButton'

type EmailsProps = {
  name: string
  entity?: AccountResponse | LeadResponse | OpportunityResponse | ContactResponse
  updateState: React.Dispatch<React.SetStateAction<ActivityResponse[]>>
  opportunity?: OpportunityResponse
  disablePrimary?: boolean
}

export function Emails({ name, entity, updateState, opportunity, disablePrimary }: EmailsProps) {
  const { getValues, control } = useFormContext()
  const { fields: emails, append: appendEmail, remove: removeEmail } = useFieldArray({ control, name })

  return (
    <>
      {emails.length ? (
        emails.map((field, i) => {
          return (
            <EmailField
              updateState={updateState}
              opportunity={opportunity ?? null}
              entity={entity}
              emailIndex={i}
              removeEmail={removeEmail}
              key={field.id}
              name={`${name}.${i}`}
              disablePrimary={disablePrimary}
            />
          )
        })
      ) : (
        <span className="text-sm text-gray-dark">No emails provided</span>
      )}
      <AddField
        label="Add Email Address"
        onClick={() => {
          appendEmail({ label: `Email #${getValues().emails.length + 1}`, address: '', id: '' })
        }}
      />
    </>
  )
}
