import Link from 'next/link'

import { ExternalLink } from '@fsg/icons'

import { ContactResponse, SelectOption } from '@app/types'
import { routes } from '@app/utils'

type ContactOption = SelectOption<string> & {
  data: ContactResponse
}

// React Select expects an option to have label and value properties
export function renderContactDisplay(option: ContactOption) {
  const href = routes.contact.basicInformation(option.value)
  const { data } = option

  return (
    <Link key={option.value} href={href}>
      <div>
        <div className="flex w-full">
          <span className="text-sm text-gray-800">{option.label}</span> <ExternalLink className="ml-auto inline" />
        </div>
        <p className="text-sm text-gray-700">{data?.currentRole?.accountName}</p>
      </div>
    </Link>
  )
}
