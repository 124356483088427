'use client'

import React from 'react'
// import pascalCase from 'just-pascal-case'
import { useFieldArray } from 'react-hook-form'

import { AccountListField } from './AccountListField'

import { AddField } from './AddField'
import { useFormContext } from './Form'

type AccountsListProps = {
  name: string
  label?: string
  placeholder: string
}

export function AccountsList({ name, label, placeholder }: AccountsListProps) {
  const { control } = useFormContext()
  const { fields: accountsList, append: appendAccount, remove: removeAccount } = useFieldArray({ control, name })
  // const formatLabel = pascalCase(label)
  return (
    <>
      {accountsList.length ? (
        accountsList.map((field, i) => {
          return (
            <AccountListField removeAccount={removeAccount} accountListIndex={i} key={field.id} name={`${name}.${i}`} placeholder={placeholder} />
          )
        })
      ) : (
        <span className="text-sm text-gray-dark">{`No ${label} provided`}</span>
      )}
      <AddField
        label={`Add ${label}`}
        onClick={() => {
          appendAccount({ label: '', url: '' })
        }}
      />
    </>
  )
}
