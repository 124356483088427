'use client'

import React, { useEffect } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import classNames from 'classnames/bind'
import { Controller, RegisterOptions } from 'react-hook-form'

import { ButtonV2 } from '@fsg/gui-bits'
import { ExternalLink, XCircle } from '@fsg/icons'
import { normalizeUrl, parseWeblink, truncateString } from '@fsg/utils'

import { renderErrorMessage } from '@app/utils'

import { useFormContext } from './Form'
import moduleStyles from './Form.module.scss'
import { FormItem } from './FormItem'

const cx = classNames.bind(moduleStyles)

const inputClasses = {
  base: 'text-sm w-full font-regular text-true-black',
  editMode: 'bg-white',
}

interface WebLinkFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  removeWebLink?: (index: number) => void
  webLinkIndex?: number
}

export function WebLinkField({ rules, placeholder = 'No url provided', removeWebLink, webLinkIndex, ...props }: WebLinkFieldProps) {
  const {
    isEditing,
    control,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext()
  rules = rules
    ? rules
    : {
        validate: (value: any) => {
          const urlRegex = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9:%._\+~#]{1,256}\.(?!com@)[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/
          if (!value || !value.trim() || !urlRegex.test(value)) {
            return 'A valid URL is required'
          }
          return true
        },
      }

  const urlInput = watch(`${props.name}.url`)
  const label = watch(`${props.name}.label`)

  if (!control) {
    throw new Error('EmailField must be used within a Form component')
  }

  const displayClassName = cx('m--form-item--input', inputClasses.base)
  const inputClassName = cx('m--form-item--input', inputClasses.base, { [inputClasses.editMode]: isEditing })

  function Display({ value }: { value: string }) {
    if (value === undefined || value === null || value === '') {
      return <span className={cx(displayClassName, 'text-gray-dark')}>{placeholder}</span>
    }
    return <span className={displayClassName}>{truncateString(value, 40)}</span>
  }

  // temporary solution, will need to change if/when custom labels are needed
  useEffect(() => {
    try {
      const url = new URL(normalizeUrl(urlInput))
      const { type: webLinkLabel } = parseWeblink(url)
      setValue(`${props.name}.label`, webLinkLabel)
    } catch (e) {
      console.error('Not a valid url >> ', urlInput)
      return
    }
  }, [props.name, setValue, urlInput])

  return (
    <Controller
      control={control}
      name={`${props.name}.url`}
      rules={rules}
      render={({ field }) => {
        return (
          <div className={isEditing ? 'grid grid-cols-[1fr_1fr_1fr_auto] items-end gap-sm' : null}>
            <div className={isEditing ? 'col-span-3' : null}>
              <FormItem label={label || ''} icon={<ExternalLink className="float-right" />} iconHref={isEditing ? '' : normalizeUrl(urlInput)}>
                {isEditing ? (
                  <div className="w-full">
                    <input className={inputClassName} {...field} {...props} placeholder={placeholder} readOnly={!isEditing} />
                  </div>
                ) : (
                  <Display value={urlInput} />
                )}
              </FormItem>
            </div>
            <div className={isEditing ? 'col-span-1 pb-1' : null}>
              {isEditing && (
                <ButtonV2
                  type="button"
                  onClick={() => {
                    removeWebLink(webLinkIndex)
                  }}
                  iconOnly
                  variant="error"
                  size="lg"
                >
                  <XCircle />
                </ButtonV2>
              )}
            </div>
            <ErrorMessage errors={errors} name={field.name} render={renderErrorMessage} />
          </div>
        )
      }}
    />
  )
}
